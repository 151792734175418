<template>
  <div>
    <el-form
      ref="nodeForm"
      :rules="rules"
      label-position="top"
      label-width="100px"
      :model="nodeToBind"
    >
      <el-form-item prop="node_name" :label="__('Name')">
        <el-input v-model="nodeToBind.node_name"></el-input>
      </el-form-item>

      <el-tabs v-model="activeTab" class="tabs" style="font-size: 1.2em;">
        <el-tab-pane :label="__('Grammar')" name="grammar">
          <el-scrollbar :native="false">
            <el-form-item
              prop="menu_node.data.user_input_nodes_setting.data.language_id"
              :label="__('Language')"
              class="is-required"
            >
              <el-select
                v-model="
                  nodeToBind.menu_node.data.user_input_nodes_setting.data
                    .language_id
                "
                style="width: 100%;"
                :placeholder="__('Please select a language')"
                default-first-option
                filterable
                clearable
                :loading-text="__('fetching languages')"
                :no-data-text="noText"
              >
                <el-option
                  v-for="(item, index) in filteredLanguages"
                  :label="item.language_name"
                  :value="item.language_id"
                  :key="index"
                />
              </el-select>
            </el-form-item>
            <el-form-item
              prop="grammar_condition"
              :label="__('Grammar Conditions')"
              class="is-required"
            >
              <match-grammar
                v-model="grammarConditions"
                :gotoOptions="generateGotoOptions"
              />
            </el-form-item>
          </el-scrollbar>
        </el-tab-pane>
        <el-tab-pane :label="__('Text-to-Speech')" name="tts_settings">
          <el-form-item
            prop="menu_node.data.user_input_nodes_setting.data.prompt_text"
            :label="__('Prompt')"
          >
            <input-variable-popper
              :value="promptText"
              is-content-editable
              @input="updateText('prompt_text', $event)"
              :is-text-area="false"
              include-prompts
              include-audio-variables
              :include-secure-variables="false"
              include-payments
              :ats="getOptionsInitiators"
              popper-class="prompt-node-popper"
              class="promptEditor"
              scroll-ref=".node-modal-menu"
            />
            <audio-player
              class="audio-player"
              :disabled="!promptText"
              @get-preview="
                generateAudio(
                  'promptText',
                  'generatingAudioForPrompt',
                  'promptAudioFile',
                  'promptAtAudioFileCreation'
                )
              "
              :show-reload="promptContentChanged"
              :generating-audio="generatingAudioForPrompt"
              :file="promptAudioFile"
            />
          </el-form-item>

          <el-form-item
            prop="menu_node.data.user_input_nodes_setting.data.fallback_prompt_text"
            :label="__('Fallback Prompt')"
          >
            <input-variable-popper
              :value="fallbackPromptText"
              is-content-editable
              @input="updateText('fallback_prompt_text', $event)"
              :is-text-area="false"
              include-prompts
              include-audio-variables
              :include-secure-variables="false"
              include-payments
              :ats="getOptionsInitiators"
              popper-class="prompt-node-popper"
              class="promptEditor"
              scroll-ref=".node-modal-menu"
            />
            <audio-player
              class="audio-player"
              :disabled="!fallbackPromptText"
              @get-preview="
                generateAudio(
                  'fallbackPromptText',
                  'generatingAudioForFallbackPrompt',
                  'fallbackPromptAudioFile',
                  'fallbackPromptAtAudioFileCreation'
                )
              "
              :show-reload="fallbackPromptContentChanged"
              :generating-audio="generatingAudioForFallbackPrompt"
              :file="fallbackPromptAudioFile"
            />
          </el-form-item>
          <el-form-item
            prop="menu_node.data.user_input_nodes_setting.data.no_input_prompt_text"
            :label="__('No Input Prompt')"
          >
            <input-variable-popper
              :value="noInputPromptText"
              is-content-editable
              @input="updateText('no_input_prompt_text', $event)"
              :is-text-area="false"
              include-prompts
              include-audio-variables
              :include-secure-variables="false"
              include-payments
              :ats="getOptionsInitiators"
              popper-class="prompt-node-popper"
              class="promptEditor"
              scroll-ref=".node-modal-menu"
            />
            <audio-player
              class="audio-player"
              :disabled="!noInputPromptText"
              @get-preview="
                generateAudio(
                  'noInputPromptText',
                  'generatingAudioForNoInputPrompt',
                  'noInputPromptAudioFile',
                  'noInputPromptAtAudioFileCreation'
                )
              "
              :show-reload="noInputPromptContentChanged"
              :generating-audio="generatingAudioForNoInputPrompt"
              :file="noInputPromptAudioFile"
            />
          </el-form-item>
          <el-form-item
            prop="menu_node.data.user_input_nodes_setting.data.no_match_prompt_text"
            :label="__('No Match Prompt')"
          >
            <input-variable-popper
              :value="noMatchPromptText"
              is-content-editable
              @input="updateNoMatchPromptText($event)"
              :is-text-area="false"
              include-prompts
              include-audio-variables
              :include-secure-variables="false"
              include-payments
              :ats="getOptionsInitiators"
              popper-class="prompt-node-popper"
              class="promptEditor"
              scroll-ref=".node-modal-menu"
            />
            <audio-player
              class="audio-player"
              :disabled="!noMatchPromptText"
              @get-preview="
                generateAudio(
                  'noMatchPromptText',
                  'generatingAudioForNoMatchPrompt',
                  'noMatchPromptAudioFile',
                  'noMatchPromptAtAudioFileCreation'
                )
              "
              :show-reload="noMatchPromptContentChanged"
              :generating-audio="generatingAudioForNoMatchPrompt"
              :file="noMatchPromptAudioFile"
            />
          </el-form-item>
        </el-tab-pane>
        <el-tab-pane :label="__('Speech Recognizer')" name="sr_settings">
          <el-scrollbar :native="false">
            <!--            <div style="max-height: 90vh">-->
            <el-form-item
              prop="menu_node.data.user_input_nodes_setting.data.barge_in"
              :label="__('Speech Controls')"
            >
              <el-checkbox
                v-model="
                  nodeToBind.menu_node.data.user_input_nodes_setting.data
                    .barge_in
                "
                style="margin-left: 2px;"
                >{{ __("Barge In") }}
              </el-checkbox>
              <el-checkbox
                v-model="
                  nodeToBind.menu_node.data.user_input_nodes_setting.data
                    .dtmf_only
                "
                style="margin-left: 2px;"
                >{{ __("Disable Voice Input") }}</el-checkbox
              >
            </el-form-item>
            <el-form-item
              prop="menu_node.data.user_input_nodes_setting.data.confidence_level"
              :label="__('Minimum Transcription Confidence Score')"
            >
              <el-input-number
                v-model="
                  nodeToBind.menu_node.data.user_input_nodes_setting.data
                    .confidence_level
                "
                :precision="2"
                :min="0.0"
                :step="0.05"
                :max="1"
                :step-strictly="false"
              ></el-input-number>
            </el-form-item>
            <el-form-item
              prop="menu_node.data.user_input_nodes_setting.data.interdigit_timeout"
              :label="__('Interdigit Timeout (Seconds)')"
            >
              <el-input-number
                v-model="
                  nodeToBind.menu_node.data.user_input_nodes_setting.data
                    .interdigit_timeout
                "
                :precision="1"
                :min="0.5"
                :step="0.5"
                :max="10"
                :step-strictly="true"
              ></el-input-number>
            </el-form-item>
          </el-scrollbar>
        </el-tab-pane>
        <el-tab-pane :label="__('Event Handler')" name="event_handler">
          <el-scrollbar :native="false">
            <div style="max-height: 90vh">
              <el-form-item
                prop="menu_node.data.user_input_nodes_setting.data.no_input_canvas_id"
              >
                <EventHandlers
                  :event-handler-canvas="noInputEventHandlerCanvas"
                  :count="
                    nodeToBind.menu_node.data.user_input_nodes_setting.data
                      .no_input_count
                  "
                  :eventHandlerLabel="__('No Input Event Handler')"
                  :event-handler-place-holder="
                    __('Select or create a no input event handler canvas')
                  "
                  @update-event-handler="setNoInputEventHandlerCanvas"
                  @update-event-handler-count="
                    setNoInputEventHandlerCount($event)
                  "
                  cssClass="is-required"
                ></EventHandlers>
              </el-form-item>
              <el-form-item
                prop="menu_node.data.user_input_nodes_setting.data.no_match_canvas_id"
              >
                <EventHandlers
                  :event-handler-canvas="noMatchEventHandlerCanvas"
                  :count="
                    nodeToBind.menu_node.data.user_input_nodes_setting.data
                      .no_match_count
                  "
                  :eventHandlerLabel="__('No Match Event Handler')"
                  :event-handler-place-holder="
                    __('Select or create a no match event handler canvas')
                  "
                  @update-event-handler="setNoMatchEventHandlerCanvas"
                  @update-event-handler-count="
                    setNoMatchEventHandlerCount($event)
                  "
                  cssClass="is-required"
                ></EventHandlers>
              </el-form-item>
            </div>
          </el-scrollbar>
        </el-tab-pane>
        <el-tab-pane
          :label="__('Advanced ASR Settings')"
          name="advanced_asr_settings"
        >
          <el-scrollbar :native="false">
            <div style="max-height: 90vh">
              <advanced-speech-parameters
                :nlp-engine="this.form_type_obj_temp.nlp_engine"
                :speech-complete-timeout="
                  nodeToBind.menu_node.data.user_input_nodes_setting.data
                    .speech_complete_timeout
                "
                :speech-incomplete-timeout="
                  nodeToBind.menu_node.data.user_input_nodes_setting.data
                    .speech_incomplete_timeout
                "
                :no-input-timeout="
                  nodeToBind.menu_node.data.user_input_nodes_setting.data
                    .no_input_timeout
                "
                :barge-in-sensitivity="
                  nodeToBind.menu_node.data.user_input_nodes_setting.data
                    .barge_in_sensitivity
                "
                :node-type="'menuNode'"
                @update-nlp-parameter="updateNlpParameters"
              >
              </advanced-speech-parameters>
            </div>
          </el-scrollbar>
        </el-tab-pane>
        <el-tab-pane :label="__('Confirmation')" name="confirm_settings">
          <speech-input-confirmation
            :taskId="task_id"
            @update-max-number-of-input-count="setMaxNumberOfInputCount($event)"
            :documentRule="inputNodeSetting"
            :showNoInputTimeout="true"
            scroll-ref=".node-modal-menu"
          />
        </el-tab-pane>
      </el-tabs>
    </el-form>
  </div>
</template>

<script>
import BaseNode from "@/views/build/callflow/components/node-type-forms/BaseNode";
import GenerateAudio from "@/views/build/callflow/components/GenerateAudio";
import { NODE_TYPES } from "@/constants/nodes";
import { mapActions, mapGetters, mapState } from "vuex";
import _ from "lodash";
import InputVariablePopper from "@/views/build/callflow/components/node-type-forms/components/InputVariablePopper";
import AudioPlayer from "@/components/AudioPlayer";
import { phraseHintsRegex } from "@/utils/regex";
import MatchGrammar from "@/views/build/callflow/components/node-type-forms/components/MatchGrammar";
import {
  filterRowsIfEveryKeyValueIsAbsent,
  getSubKeyObject
} from "@/utils/collection";
import EventHandlers from "@/views/build/callflow/components/node-type-forms/components/EventHandlers";
import SpeechInputConfirmation from "@/views/build/callflow/components/audioInputConfirmation/ConfirmationElement.vue";
import AdvancedSpeechParameters from "@/components/AdvancedSpeechParameters.vue";
import { eventHandlerNameValidation } from "@/utils/formValidationRules";
import { sanitizeInput } from "@/utils/promptText";

const initMenuNode = {
  node_type: NODE_TYPES.MENU.NODE_TYPE,
  menu_node: {
    data: {
      property_rules: {
        data: {
          transfer_phone_number: "",
          display_name: "",
          ani: ""
        }
      },
      transfer_timeout: null,
      no_answer_busy_canvas_id: null,
      user_input_nodes_setting: {
        data: {
          no_input_timeout: 5000,
          speech_complete_timeout: 1000,
          speech_incomplete_timeout: 20000,
          barge_in_sensitivity: 0.5
        }
      }
    }
  }
};
const eventHandlerCanvasInitialize = {
  canvas_name: "",
  canvas_id: -1,
  msg: ""
};

export default {
  components: {
    InputVariablePopper,
    AudioPlayer,
    MatchGrammar,
    EventHandlers,
    SpeechInputConfirmation,
    AdvancedSpeechParameters
  },
  mixins: [BaseNode, GenerateAudio],
  data() {
    const validateNoInputEventHandler = (rule, value, callback) => {
      eventHandlerNameValidation(value, callback, this, "input");
    };
    const validateNoMatchEventHandler = (rule, value, callback) => {
      eventHandlerNameValidation(value, callback, this, "match");
    };
    const validateGrammar = (rule, value, callback) => {
      if (this.nodeToBind.menu_node.data.grammar_conditions.data.length < 1) {
        callback(
          // eslint-disable-next-line
          __("At least one valid grammar with sub-node is required before saving this node")
        );
      } else {
        if (this.isNodeSubmit) {
          this.isSubmitAction = true;
          if (
            !_.some(
              this.nodeToBind.menu_node.data.grammar_conditions.data,
              function(obj) {
                // validation passes if all mandatory fields are empty
                if (
                  obj.dtmf.toString().trim() === "" &&
                  obj.grammar.toString().trim() === "" &&
                  obj.node_name.toString().trim() === ""
                ) {
                  return false;
                } else if (
                  obj.dtmf.toString().trim() === "" &&
                  obj.node_name.toString().trim() === ""
                ) {
                  callback(__("DTMF and Node name cannot be empty"));
                  return true;
                } else if (obj.dtmf.toString().trim() === "") {
                  callback(__("DTMF cannot be empty"));
                  return true;
                } else if (obj.node_name.toString().trim() === "") {
                  callback(__("Node cannot be empty"));
                  return true;
                }
              }
            )
          ) {
            callback();
          }
        }
        callback();
      }
    };
    return {
      rules: {
        "menu_node.data.user_input_nodes_setting.data.language_id": [
          {
            required: true,
            message: __("Please select a language"),
            trigger: "blur"
          }
        ],

        "menu_node.data.user_input_nodes_setting.data.prompt_text": [
          {
            required: true,
            message: __("Please add a prompt text"),
            trigger: "blur"
          }
        ],

        "menu_node.data.user_input_nodes_setting.data.fallback_prompt_text": [
          {
            required: true,
            message: __("Please add a fallback prompt text"),
            trigger: "blur"
          }
        ],

        "menu_node.data.user_input_nodes_setting.data.no_input_canvas_id": [
          {
            validator: validateNoInputEventHandler,
            trigger: "change"
          }
        ],
        "menu_node.data.user_input_nodes_setting.data.no_match_canvas_id": [
          {
            validator: validateNoMatchEventHandler,
            trigger: "change"
          }
        ],
        grammar_condition: {
          validator: validateGrammar,
          trigger: "change"
        }
      },
      variableRulesBkup: [],
      currentSelectionIndex: 0,
      selectedReturnValues: [],
      activeTab: "grammar",
      promptAudioFile: "",
      fallbackPromptAudioFile: "",
      noInputPromptAudioFile: "",
      noMatchPromptAudioFile: "",
      generatingAudioForPrompt: false,
      generatingAudioForFallbackPrompt: false,
      generatingAudioForNoInputPrompt: false,
      generatingAudioForNoMatchPrompt: false,
      isSubmitAction: false,
      generatingAudio: false,
      promptAtAudioFileCreation: "",
      fallbackPromptAtAudioFileCreation: "",
      noInputPromptAtAudioFileCreation: "",
      noMatchPromptAtAudioFileCreation: "",
      form_type_obj_temp: {},
      currentTab: "",
      allowedRegex: phraseHintsRegex,
      tabStructure: {
        grammar: ["grammar_condition"],
        tts_settings: [
          "menu_node.data.user_input_nodes_setting.data.prompt_text",
          "menu_node.data.user_input_nodes_setting.data.fallback_prompt_text"
        ],
        sr_setting: [],
        event_handler: [
          "menu_node.data.user_input_nodes_setting.data.no_input_canvas_id",
          "menu_node.data.user_input_nodes_setting.data.no_match_canvas_id"
        ]
      }
    };
  },
  computed: {
    ...mapState("prompts", {
      prompts: state => state.prompts,
      promptsLoading: state => state.loading
    }),
    ...mapGetters("canvas", {
      getEventHandlerCanvasList: "getEventHandlerCanvasList"
    }),
    ...mapGetters("variables", {
      arrayVariable: "arrayVariable",
      arrayVariableName: "arrayVariableName"
    }),
    ...mapState("asrLanguages", {
      asrLanguages: state => state.asrLanguages,
      asrLanguagesLoading: state => state.loading
    }),
    inputNodeSetting: {
      get: function() {
        return _.get(
          this.nodeToBind.menu_node.data,
          "user_input_nodes_setting",
          {}
        );
      },
      set: function(value) {
        this.$set(
          this.nodeToBind.menu_node.data,
          "user_input_nodes_setting",
          value
        );
      }
    },
    filteredLanguages() {
      let asrLanguages = [];
      let self = this;

      asrLanguages = _.filter(this.asrLanguages, [
        "language_provider",
        "lumenvox"
      ]);
      self.preText = "Lumenvox";
      return asrLanguages;
    },
    noInputEventHandlerCanvas() {
      return this.nodeToBind.menu_node.data.user_input_nodes_setting.data
        .no_input_canvas_id;
    },
    noMatchEventHandlerCanvas() {
      return this.nodeToBind.menu_node.data.user_input_nodes_setting.data
        .no_match_canvas_id;
    },
    promptText() {
      return this.nodeToBind.menu_node.data.user_input_nodes_setting.data
        .prompt_text;
    },
    fallbackPromptText() {
      return this.nodeToBind.menu_node.data.user_input_nodes_setting.data
        .fallback_prompt_text;
    },
    noInputPromptText() {
      return sanitizeInput(
        this.nodeToBind.menu_node.data.user_input_nodes_setting.data
          .no_input_prompt_text
      );
    },
    noMatchPromptText() {
      return sanitizeInput(
        this.nodeToBind.menu_node.data.user_input_nodes_setting.data
          .no_match_prompt_text
      );
    },
    phraseHints() {
      return this.nodeToBind.menu_node.data.phrase_hints;
    },

    formParameters() {
      return this.nodeToBind.menu_node.data.user_input_nodes_setting.data
        .form_parameters;
    },
    getOptionsInitiators() {
      return ["{{", "[["];
    },
    promptContentChanged() {
      return this.promptText !== this.promptAtAudioFileCreation;
    },
    fallbackPromptContentChanged() {
      return this.fallbackPromptText !== this.fallbackPromptAtAudioFileCreation;
    },
    noInputPromptContentChanged() {
      return this.noInputPromptText !== this.noInputPromptAtAudioFileCreation;
    },
    noMatchPromptContentChanged() {
      return this.noMatchPromptText !== this.noMatchPromptAtAudioFileCreation;
    },

    allGrammarConditions() {
      return _.isEmpty(this.nodeToBind.menu_node.data.grammar_conditions)
        ? { data: [] }
        : {
            data: _.map(
              this.nodeToBind.menu_node.data.grammar_conditions.data,

              (grammarCondition, index) => {
                grammarCondition["error"] = "";
                if (grammarCondition.node_id === -1) {
                  grammarCondition["error"] = !_.map(
                    this.getValidNodes,
                    "node_name"
                  ).includes(grammarCondition.node_name)
                    ? ""
                    : __("Node ':nodeName' cannot be used here", {
                        nodeName: grammarCondition.node_name
                      });
                }
                if (
                  grammarCondition.node_id === -1 &&
                  grammarCondition.node_name.toLowerCase() === "disconnect"
                ) {
                  grammarCondition["error"] =
                    " '" +
                    `${grammarCondition.node_name}` +
                    "' " +
                    __("cannot be used as node name");
                }

                grammarCondition["grammar_error"] = "";
                grammarCondition["dtmf_error"] = "";
                grammarCondition["node_name_error"] = "";

                const subKeys = ["node_id", "node_name", "dtmf", "grammar"];
                let grammarConditionRemoveLastEmptyRow = _.cloneDeep(
                  this.nodeToBind.menu_node.data.grammar_conditions.data
                );
                let grammarConditionCollectionSize = _.size(
                  grammarConditionRemoveLastEmptyRow
                );
                if (grammarConditionCollectionSize > 1) {
                  grammarConditionRemoveLastEmptyRow.splice(
                    grammarConditionCollectionSize - 1,
                    1
                  );
                }
                if (grammarConditionCollectionSize - 1 !== index) {
                  grammarCondition["grammar_error"] =
                    _.some(
                      grammarConditionRemoveLastEmptyRow,
                      condition =>
                        !_.isEqual(
                          getSubKeyObject(grammarCondition, subKeys),
                          getSubKeyObject(condition, subKeys)
                        ) &&
                        grammarCondition.grammar.toString().trim() ===
                          condition.grammar.toString().trim()
                    ) && !_.isEmpty(grammarCondition.grammar.toString().trim())
                      ? __("Grammar conflict")
                      : "";

                  grammarCondition["dtmf_error"] = _.some(
                    grammarConditionRemoveLastEmptyRow,
                    condition =>
                      !_.isEqual(
                        getSubKeyObject(grammarCondition, subKeys),
                        getSubKeyObject(condition, subKeys)
                      ) &&
                      grammarCondition.dtmf.toString().trim() ===
                        condition.dtmf.toString().trim()
                  )
                    ? __("DTMF conflict")
                    : "";
                  if (this.isSubmitAction) {
                    if (
                      grammarCondition.dtmf.toString().trim() === "" &&
                      grammarCondition.grammar.toString().trim() !== ""
                    ) {
                      grammarCondition["dtmf_error"] = __(
                        "DTMF cannot be empty"
                      );
                    }
                    if (
                      grammarCondition.node_name.toString().trim() === "" &&
                      grammarCondition.grammar.toString().trim() !== ""
                    ) {
                      grammarCondition["node_name_error"] = __(
                        "Node cannot be empty"
                      );
                    }
                  }
                }
                grammarCondition["msg"] = !grammarCondition.error
                  ? grammarCondition["msg"] || ""
                  : "";
                return grammarCondition;
              }
            )
          };
    },
    grammarConditions: {
      get: function() {
        const { data: grammar_conditions } = this.allGrammarConditions;
        return _.isEmpty(grammar_conditions) ? [] : grammar_conditions;
      },
      set: function(val) {
        const currentGrammarMatches = this.grammarConditions;
        if (
          // update only if there is a change in grammar conditions excluding the otherwise node
          !_.isEqual(currentGrammarMatches, val) ||
          _.isEmpty(currentGrammarMatches)
        ) {
          this.$set(
            this.nodeToBind.menu_node.data.grammar_conditions,
            "data",
            val
          );
        }
      }
    },

    /**
     * method to do extra checks to validate form, it cannot be handled by the element UI form validations
     * @returns {boolean}
     */
    formHasErrors() {
      const { data: grammar_conditions } = this.allGrammarConditions;

      return (
        _.some(grammar_conditions, condition => {
          return condition.error && condition.error.length;
        }) ||
        _.some(grammar_conditions, condition => {
          return condition.grammar_error && condition.grammar_error.length;
        }) ||
        _.some(grammar_conditions, condition => {
          return condition.dtmf_error && condition.dtmf_error.length;
        }) ||
        _.some(grammar_conditions, condition => {
          return condition.node_name_error && condition.node_name_error.length;
        })
      );
    },
    transferPhoneNumber: {
      get() {
        return (
          this.nodeToBind.menu_node.data.property_rules.data
            .transfer_phone_number || "{}"
        );
      },
      set({ expression }) {
        this.nodeToBind.menu_node.data.property_rules.data.transfer_phone_number = expression;
      }
    },

    ani: {
      get() {
        return this.nodeToBind.menu_node.data.property_rules.data.ani || "{}";
      },
      set({ expression }) {
        this.nodeToBind.menu_node.data.property_rules.data.ani = expression;
      }
    },

    displayName: {
      get() {
        return (
          this.nodeToBind.menu_node.data.property_rules.data.display_name ||
          "{}"
        );
      },
      set({ expression }) {
        this.nodeToBind.menu_node.data.property_rules.data.display_name = expression;
      }
    }
  },
  async created() {
    if (!this.nodeToBind.node_id || _.isEmpty(this.nodeToBind.menu_node)) {
      this.initializeMenuNodeData();
      this.$set(this.nodeToBind, "menu_node", {});
      this.$set(this.nodeToBind.menu_node, "data", {});
      if (_.isEmpty(this.nodeToBind.menu_node.data.grammar_conditions)) {
        this.$set(this.nodeToBind.menu_node.data, "grammar_conditions", {});
      }
      this.$set(this.nodeToBind.menu_node.data, "user_input_nodes_setting", {});
      this.$set(
        this.nodeToBind.menu_node.data.user_input_nodes_setting,
        "data",
        {
          ...SpeechInputConfirmation.default_value
        }
      );
      this.$set(
        this.nodeToBind.menu_node.data.user_input_nodes_setting.data,
        "barge_in",
        false
      );
      this.$set(
        this.nodeToBind.menu_node.data.user_input_nodes_setting.data,
        "no_match_count",
        2
      );
      this.$set(
        this.nodeToBind.menu_node.data.user_input_nodes_setting.data,
        "no_input_count",
        2
      );
      this.$set(
        this.nodeToBind.menu_node.data.user_input_nodes_setting.data,
        "prompt_text",
        ""
      );
      this.$set(
        this.nodeToBind.menu_node.data.user_input_nodes_setting.data,
        "fallback_prompt_text",
        ""
      );
      this.$set(
        this.nodeToBind.menu_node.data.user_input_nodes_setting.data,
        "no_input_prompt_text",
        ""
      );
      this.$set(
        this.nodeToBind.menu_node.data.user_input_nodes_setting.data,
        "no_input_timeout",
        5000
      );
      this.$set(
        this.nodeToBind.menu_node.data.user_input_nodes_setting.data,
        "no_match_prompt_text",
        ""
      );
      this.$set(
        this.nodeToBind.menu_node.data.user_input_nodes_setting.data,
        "form_parameters",
        ""
      );
      this.$set(
        this.nodeToBind.menu_node.data.user_input_nodes_setting.data,
        "confidence_level",
        0.5
      );
      this.$set(
        this.nodeToBind.menu_node.data.user_input_nodes_setting.data,
        "interdigit_timeout",
        2.0
      );
      this.$set(
        this.nodeToBind.menu_node.data.user_input_nodes_setting.data,
        "no_input_canvas_id",
        _.cloneDeep(eventHandlerCanvasInitialize)
      );
      this.$set(
        this.nodeToBind.menu_node.data.user_input_nodes_setting.data,
        "no_match_canvas_id",
        _.cloneDeep(eventHandlerCanvasInitialize)
      );
      this.$set(
        this.nodeToBind.menu_node.data.user_input_nodes_setting.data,
        "language_id",
        ""
      );
      this.$set(
        this.nodeToBind.menu_node.data.user_input_nodes_setting.data,
        "speech_complete_timeout",
        1000
      );
      this.$set(
        this.nodeToBind.menu_node.data.user_input_nodes_setting.data,
        "speech_incomplete_timeout",
        20000
      );
      this.$set(
        this.nodeToBind.menu_node.data.user_input_nodes_setting.data,
        "barge_in_sensitivity",
        0.5
      );
      this.$set(this.nodeToBind, "node_type", NODE_TYPES.MENU.NODE_TYPE);
    } else {
      if (
        this.nodeToBind.menu_node.data.user_input_nodes_setting.data
          .no_input_canvas_id > 0
      ) {
        this.$set(
          this.nodeToBind.menu_node.data.user_input_nodes_setting.data,
          "no_input_canvas_id",
          Object.assign({}, eventHandlerCanvasInitialize, {
            canvas_id: this.noInputEventHandlerCanvas
          })
        );
      }

      if (
        this.nodeToBind.menu_node.data.user_input_nodes_setting.data
          .no_match_canvas_id > 0
      ) {
        this.$set(
          this.nodeToBind.menu_node.data.user_input_nodes_setting.data,
          "no_match_canvas_id",
          Object.assign({}, eventHandlerCanvasInitialize, {
            canvas_id: this.noMatchEventHandlerCanvas
          })
        );
      }

      if (!_.has(this.nodeToBind.menu_node.data, "user_input_nodes_setting")) {
        this.$set(
          this.nodeToBind.menu_node.data,
          "user_input_nodes_setting",
          {}
        );
        this.$set(
          this.nodeToBind.menu_node.data.user_input_nodes_setting,
          "data",
          {
            ...SpeechInputConfirmation.default_value
          }
        );
      }
    }
    this.initializePrompts();
    await this.initializeLanguages();
    await this.checkASRLanguagesLength();
  },
  methods: {
    ...mapActions("prompts", {
      getPrompts: "getPrompts"
    }),
    ...mapActions("asrLanguages", {
      getASRLanguages: "getASRLanguages"
    }),
    initializeMenuNodeData() {
      this.nodeToBind = Object.assign(
        {},
        this.nodeToBind,
        _.cloneDeep(initMenuNode)
      );
    },
    initializePrompts() {
      if (!this.prompts.length) {
        this.getPrompts();
      }
    },
    async initializeLanguages() {
      await this.getASRLanguages({ fetch_all: 0 });
    },

    async checkASRLanguagesLength() {
      if (!this.asrLanguages.length && !this.asrLanguagesLoading) {
        this.$message({
          type: "error",
          // eslint-disable-next-line
          message: __("ASR languages are not provisioned in your business plan")
        });
      }
    },

    updateText(key, value) {
      this.$set(
        this.nodeToBind.menu_node.data.user_input_nodes_setting.data,
        key,
        sanitizeInput(value)
      );
    },
    updateNoMatchPromptText(value) {
      this.$set(
        this.nodeToBind.menu_node.data.user_input_nodes_setting.data,
        "no_match_prompt_text",
        sanitizeInput(value)
      );
    },

    setNoInputEventHandlerCanvas(option) {
      this.$set(
        this.nodeToBind.menu_node.data.user_input_nodes_setting.data,
        "no_input_canvas_id",
        option
      );
      this.$refs.nodeForm.validateField(
        "menu_node.data.user_input_nodes_setting.data.no_input_canvas_id"
      );
    },
    setNoMatchEventHandlerCanvas(option) {
      this.$set(
        this.nodeToBind.menu_node.data.user_input_nodes_setting.data,
        "no_match_canvas_id",
        option
      );
      this.$refs.nodeForm.validateField(
        "menu_node.data.user_input_nodes_setting.data.no_match_canvas_id"
      );
    },
    setNoMatchEventHandlerCount(val) {
      this.$set(
        this.nodeToBind.menu_node.data.user_input_nodes_setting.data,
        "no_match_count",
        val
      );
    },
    setNoInputEventHandlerCount(val) {
      this.$set(
        this.nodeToBind.menu_node.data.user_input_nodes_setting.data,
        "no_input_count",
        val
      );
    },
    setMaxNumberOfInputCount(val) {
      this.$set(
        this.nodeToBind.menu_node.data.user_input_nodes_setting.data,
        "no_max_number_of_input_count",
        val
      );
    },
    updateNlpParameters(nlp_param, value) {
      // console.log(nlp_param, value);
      this.$set(
        this.nodeToBind.menu_node.data.user_input_nodes_setting.data,
        nlp_param,
        value
      );
    },

    cleanUpNodeToPrepareForSubmit() {
      const nodeToCleanUp = _.cloneDeep(this.nodeToBind);

      // cleanup keyword_matches
      const grammarConditions = _.map(
        filterRowsIfEveryKeyValueIsAbsent(
          _.map(this.allGrammarConditions.data, grammarCondition => ({
            ...grammarCondition,
            dtmf: grammarCondition.dtmf.toString().trim(),
            grammar: grammarCondition.grammar.toString().trim(),
            node_name: grammarCondition.node_name.toString().trim()
          })),
          "dtmf,grammar,node_name"
        ),
        condition => {
          const { dtmf, grammar, node_id, node_name } = condition;
          return { dtmf, grammar, node_id, node_name };
        }
      );

      this.$set(
        nodeToCleanUp.menu_node.data.grammar_conditions,
        "data",
        grammarConditions
      );

      return nodeToCleanUp;
    },
    cleanUpNode() {
      // the manipulation to clean up the node
      // is moved to the above method. So we can
      // reassign the nodeToBind object as the object
      // that is returned from the cleanUpNodeToPrepareForSubmit method
      if (!this.formHasErrors) {
        this.nodeToBind = this.cleanUpNodeToPrepareForSubmit();
        this.createOrEditNode();
      } else {
        this.toggleNodeSubmit(false);
      }
    },
    createOrEditNode() {
      if (!this.isTaskReadOnly) {
        this.attemptedToSubmit = true;
        this.$refs.nodeForm.validate((valid, errors) => {
          if (valid) {
            this.process({
              node: this.nodeToBind,
              nodeInContext: this.node
            })
              .then(async () => {
                this.newVariableCreated
                  ? await this.forceFetchVariables()
                  : null;
                this.setClickedNode(null);
                this.toggleNodeSubmit(false);
              })
              .catch(() => {
                // this.newVariableCreated = false;
                this.toggleNodeSubmit(false);
              });
          } else {
            let errMessages = _.flatten(
              _.map(errors, err => {
                return _.map(err, "message");
              })
            );

            _.map(errMessages, message => {
              setTimeout(() => this.errorNotification(message), 100);
            });
            this.toggleNodeSubmit(false);
            // this.newVariableCreated = false;
            let errorPropName = Object.keys(errors);
            let findTab = _.findKey(this.tabStructure, function(structure) {
              return structure.some(r => errorPropName.includes(r));
            });

            if (!_.isEmpty(findTab)) {
              this.activeTab = findTab;
            }
            return false;
          }
        });
      }
    }
  },
  // Watch gets triggered before computed property
  // Proper check must be in place to ensure that computed data is available before any process
  watch: {}
};
</script>

<style lang="scss" scoped>
@import "~@/styles/element-variables.scss";
@import "~@/styles/node_common.scss";

::v-deep .row-message {
  font-size: 0.75rem;
  color: $--color-success;
  margin-left: 10px;
}

.audio-player {
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.promptEditor {
  ::v-deep .editableContent {
    height: 200px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border: 1px solid #a0a8b5;
    outline: none;
    padding: 10px;
    overflow: auto;
    /*&:focus {*/
    /*  border: 1px solid black;*/
    /*}*/
  }
}
</style>
